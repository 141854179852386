export default {
    methods: {
        formatDate(timestamp) {
          try {
            let myDate = new Date(timestamp.seconds * 1000);
            return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()} ${myDate.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}`;
          } catch (e) {
            // console.log(e);
          }
        },
        shortFormatDate(timestamp) {
          try {
            let myDate = new Date(timestamp.seconds * 1000);
            return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()}`;
          } catch (e) {
            // console.log(e);
          }
        },
        shortFormatDateMinusWeek(timestamp) {
          try {
            let myDate = new Date(timestamp.seconds * 1000);
            let newDate = new Date(myDate.setDate(myDate.getDate() + 7));
            return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
          } catch (e) {
            // console.log(e);
          }
        },
        formatLongDate(timestamp) {
          try {
            let myDate = new Date(timestamp.seconds * 1000);
            return myDate.toLocaleString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric" })
          } catch (e) {
            // console.log(e)
          }
        }
    }    
}