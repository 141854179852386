import { render, staticRenderFns } from "./CoachFeed.vue?vue&type=template&id=091d5425&scoped=true&"
import script from "./CoachFeed.vue?vue&type=script&lang=js&"
export * from "./CoachFeed.vue?vue&type=script&lang=js&"
import style0 from "./CoachFeed.vue?vue&type=style&index=0&id=091d5425&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "091d5425",
  null
  
)

export default component.exports