<template>
    <div>
        <div v-for="(item, i) in coachFeed" :key="i">
            <!--begin::Feed Widget-->
            <div class="card card-custom gutter-b">
                <!--begin::Text-->
                <div class="card-body" v-if="item.type == 'text' && item.online == true">
                    <!--begin::Top-->
                    <div class="d-flex align-items-center">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-45 symbol-light mr-5">
                            <span class="symbol-label bg-white">
                                <span class="symbol symbol-xxl-45 mr-5 align-self-start align-self-xxl-center ml-15">
                                    <img :src="item.user.profileimage" alt="image" />
                                </span>
                            </span>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Info-->
                        <div class="d-flex flex-column flex-grow-1">
                            <div class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder">
                                {{ item.user.firstname }}
                            </div>
                            <div class="d-flex">
                                <div class="d-flex align-items-center pr-5">
                                    <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Clock.svg-->
                                        <span class="padr-1">
                                            <i class="fa fa-clock icon-nm text-primary"></i>
                                        </span>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="text-dark font-weight-bold">
                                        <timeago :datetime="formatDate(item.datetime)"></timeago>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--begin::Dropdown-->
                        <router-link :to="{ name: 'feeddetailother', params: { feedId: item.id } }" v-show="showMore">
                            <button
                                class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5">
                                More <i class="fa fa-angle-down icon-nm text-white"></i>
                            </button>
                        </router-link>
                        <!--end::Dropdown-->

                        <!--end::Info-->
                    </div>
                    <!--end::Top-->
                    <!--begin::Bottom-->
                    <div class="pt-3">
                        <!--begin::Text-->
                        <p class="text-dark font-size-lg font-weight-normal pt-5 mb-6" v-if="item.title"
                            v-html="item.title">
                        </p>
                        <p class="pt-5 mb-6" v-else>&nbsp;</p>
                        <!--end::Text-->
                        <span
                            class="btn btn-danger btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5 nocursor"
                            v-if="item.likeCount > 0">

                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                            <span class="svg-icon svg-icon-md svg-icon-primary mr-0">
                                <i class="fa fa-heart icon-nm text-white"></i>
                            </span>

                            <!--end::Svg Icon-->
                            {{ item.likeCount }} <span v-if="item.likeCount == 1">Like</span><span v-else>Likes</span>
                        </span>

                        <!--begin::Action-->
                        <router-link
                            :to="{ name: 'feeddetail', params: { feedId: item.id, sessionId: item.sessionid, coachlistId: item.coachlistid } }"
                            v-if="item.messageCount > 0 && dashboard">
                            <button
                                class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5">

                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                                <!-- <span class="btn btn-icon btn-sm text-white">
                                                        <i class="flaticon2-sms icon-ms text-white"></i>
                                                    </span> -->

                                <!--end::Svg Icon-->
                                {{ item.messageCount }} <span v-if="item.messageCount == 1">Comment</span><span
                                    v-else>Comments</span>
                            </button>
                        </router-link>
                        <button
                            class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5"
                            v-if="item.messageCount > 0 && !dashboard" @click="firstLoad">

                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                            <!-- <span class="btn btn-icon btn-sm text-white">
                                                        <i class="flaticon2-sms icon-ms text-white"></i>
                                                    </span> -->

                            <!--end::Svg Icon-->
                            {{ item.messageCount }} <span v-if="item.messageCount == 1">Comment</span><span
                                v-else>Comments</span>
                        </button>
                        <!--end::Action-->
                    </div>

                    <!--begin::Comments-->
                    <div v-for="(item, i) in visibleFeeds" :key="i" :id="'feedreplies-' + item.id" v-if="!dashboard">
                        <div class="d-flex pt-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40 symbol-light-success mr-5 mt-1">
                                <span class="symbol-label bg-white">
                                    <img :src="item.user.profileimage" class="h-100 align-self-end br-42" alt="" />
                                </span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Info-->
                            <div class="d-flex flex-column flex-row-fluid">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center flex-wrap">
                                    <a href="#"
                                        class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{
            item.user.firstname }}</a>
                                    <span class="text-dark font-weight-normal flex-grow-1 font-size-sm">
                                        <timeago :datetime="formatDate(item.datetime)"></timeago>
                                    </span>
                                    <button class="text-dark font-weight-normal font-size-sm"
                                        @click="setReply(item.id)">Reply</button>
                                    <button class="text-dark font-weight-normal font-size-sm ml-2"
                                        v-if="currentUser.user_id == item.user.user_id"
                                        @click="deletePost(item.id, i)">Delete</button>
                                </div>
                                <span class="text-dark font-size-sm font-weight-normal pt-1">{{ item.text }}</span>
                                <!--end::Info-->
                            </div>
                            <!--end::Info-->
                        </div>
                        <div v-if="item.replyCount > 0" class="pt-2 pl-10">
                            <span class="text-dark font-weight-normal flex-grow-1 font-size-sm pointer"
                                @click="getReplies(item.id, i)">
                                --Show {{ item.replyCount }} <span v-if="item.replyCount == 1">reply</span><span
                                    v-else>replies</span>
                            </span>
                        </div>
                        <div v-if="item.replies.length > 0">
                            <div class="d-flex pl-15" v-for="(item, i) in item.replies" :key="i"
                                :id="'replies-' + item.id">
                                <div class="w100">
                                    <div class="d-flex pt-5">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-40 symbol-light-success mr-5 mt-1">
                                            <span class="symbol-label bg-white">
                                                <img :src="item.user.profileimage" class="h-100 align-self-end br-42"
                                                    alt="" />
                                            </span>
                                        </div>
                                        <!--end::Symbol-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-column flex-row-fluid">
                                            <!--begin::Info-->
                                            <div class="d-flex align-items-center flex-wrap">
                                                <a href="#"
                                                    class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{
            item.user.firstname }}</a>
                                                <span class="text-dark font-weight-normal flex-grow-1 font-size-sm">
                                                    <timeago :datetime="formatDate(item.datetime)"></timeago>
                                                </span>
                                                <!-- <span class="text-dark font-weight-normal font-size-sm">Reply</span> -->
                                                <button
                                                    class="text-dark font-weight-normal font-size-sm ml-2">Delete</button>
                                            </div>
                                            <span class="text-dark font-size-sm font-weight-normal pt-1">{{ item.text
                                                }}</span>
                                            <!--end::Info-->
                                        </div>
                                        <!--end::Info-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Comments-->

                    <!-- begin::Load More-->
                    <button
                        class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5"
                        @click="feedVisible += step" v-if="feedVisible < feedList.length && !dashboard">More</button>
                    <!-- end::Load More-->


                    <!--end::Bottom-->
                    <!--begin::Separator-->
                    <div class="separator separator-solid mt-5 mb-4" v-if="!dashboard"></div>
                    <!--end::Separator-->
                    <!--begin::Add Comment-->
                    <!--begin::Add Comment-->
                    <form class="position-relative" v-if="!dashboard && currentTextfield == 'comment'">
                        <textarea v-model="form.post" id="kt_forms_widget_9_input"
                            class="form-control border-0 p-0 pr-10 resize-none" rows="1"
                            placeholder="Add a comment"></textarea>
                        <div class="position-absolute top-0 right-0 mt-n1 mr-n2" @click="addPost">
                            <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                <i class="flaticon2-send-1 icon-ms"></i>
                            </span>
                        </div>
                    </form>
                    <form class="position-relative" v-if="!dashboard && currentTextfield == 'reply'">
                        <textarea v-model="replyForm.reply" id="kt_forms_widget_9_input"
                            class="form-control border-0 p-0 pr-10 resize-none" rows="1"
                            placeholder="Add a reply"></textarea>
                        <div class="position-absolute top-0 right-0 mt-n1 mr-n2" @click="addReply">
                            <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                <i class="flaticon2-send-1 icon-ms"></i>
                            </span>
                        </div>
                        <div class="position-absolute top-0 r30 mt-n1 mr-n2" @click="closeReply">
                            <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                <i class="flaticon2-cancel icon-ms"></i>
                            </span>
                        </div>
                    </form>
                    <!--edit::Editor-->
                    <!--begin::Top-->
                    <div class="d-flex align-items-center pt-10">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-45 symbol-light mr-5">
                            <span class="symbol-label bg-white">
                                <span
                                    class="symbol symbol-45 symbol-xxl-45 mr-5 align-self-start align-self-xxl-center ml-15">
                                    <img :src="item.coach.profileimage" alt="image" />
                                </span>
                            </span>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Info-->
                        <div class="d-flex flex-column flex-grow-1">
                            <div class="d-flex">
                                <div class="d-flex align-items-center pr-5 text-dark font-size-xl font-weight-bolder">
                                    Coach
                                </div>
                            </div>
                            <div class="text-dark text-hover-primary mb-1 font-size-lg">
                                {{ item.coach.first }} {{ item.coach.last }}
                            </div>
                        </div>
                        <!--end::Info-->
                    </div>
                    <!--end::Top-->
                </div>
                <!--end::Text-->
                <!--begin::Session-->
                <div :id="item.sessionid" class="card-body" v-if="item.type == 'session' && item.videourl != ''">
                    <!--begin::Top-->
                    <div class="d-flex align-items-center">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-45 symbol-light mr-5">
                            <span class="symbol-label bg-white">
                                <span
                                    class="symbol symbol-45 symbol-xxl-45 mr-5 align-self-start align-self-xxl-center ml-15">
                                    <img :src="item.coach.profileimage" alt="image" />
                                </span>
                            </span>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Info-->
                        <div class="d-flex flex-column flex-grow-1">
                            <div class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder">
                                {{ item.coach.first }} {{ item.coach.last }}
                            </div>
                            <div class="d-flex">
                                <div class="d-flex align-items-center pr-5">
                                    <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Clock.svg-->
                                        <span class="padr-1">
                                            <i class="fa fa-clock icon-nm text-primary"></i>
                                        </span>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="text-dark font-weight-bold">
                                        <timeago :datetime="formatDate(item.datetime)"></timeago>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--end::Info-->
                        <!--begin::Dropdown-->
                        <router-link
                            :to="{ name: 'feeddetail', params: { feedId: item.id, sessionId: item.sessionid, coachlistId: item.coachlistid } }"
                            v-show="showMore">
                            <button
                                class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5">
                                More <i class="fa fa-angle-down icon-nm text-white"></i>
                            </button>
                        </router-link>
                        <!--end::Dropdown-->
                    </div>
                    <!--end::Top-->
                    <!--begin::Bottom-->
                    <div class="pt-3">
                        <!--begin::Text-->
                        <p class="text-dark font-size-lg font-weight-normal pt-5 mb-6" v-if="item.title"
                            v-html="item.title">
                        </p>
                        <!-- <p v-else>&nbsp;</p> -->
                        <!--end::Text-->
                        <!--begin::Video-->
                        <div class="smartphone mt-5 mb-5">
                            <div class="content w-100 h-100 p-0">
                                <VideoPlayer :url=item.videourl :poster=item.thumbnail />
                            </div>
                        </div>
                        <!--end::Video-->
                        <!--begin::Action-->
                        <span
                            class="btn btn-success btn-sm font-weight-bolder font-size-sm text-white mr-5 mt-5 nocursor"
                            v-if="item.online == true">
                            Online
                        </span>
                        <span
                            class="btn btn-danger btn-sm font-weight-bolder font-size-sm text-white mr-5 mt-5 nocursor"
                            v-else>
                            Offline
                        </span>
                        <span
                            class="btn btn-danger btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5 nocursor"
                            v-if="item.likeCount > 0">

                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                            <span class="svg-icon svg-icon-md svg-icon-primary mr-0">
                                <i class="fa fa-heart icon-nm text-white"></i>
                            </span>

                            <!--end::Svg Icon-->
                            {{ item.likeCount }} <span v-if="item.likeCount == 1">Like</span><span v-else>Likes</span>
                        </span>

                        <!--begin::Action-->
                        <router-link
                            :to="{ name: 'feeddetail', params: { feedId: item.id, sessionId: item.sessionid, coachlistId: item.coachlistid } }"
                            v-if="item.messageCount > 0 && dashboard">
                            <button
                                class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5">

                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                                <!-- <span class="btn btn-icon btn-sm text-white">
                                                        <i class="flaticon2-sms icon-ms text-white"></i>
                                                    </span> -->

                                <!--end::Svg Icon-->
                                {{ item.messageCount }} <span v-if="item.messageCount == 1">Comment</span><span
                                    v-else>Comments</span>
                            </button>
                        </router-link>
                        <button
                            class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5"
                            v-if="item.messageCount > 0 && !dashboard" @click="firstLoad">

                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                            <!-- <span class="btn btn-icon btn-sm text-white">
                                                        <i class="flaticon2-sms icon-ms text-white"></i>
                                                    </span> -->

                            <!--end::Svg Icon-->
                            {{ item.messageCount }} <span v-if="item.messageCount == 1">Comment</span><span
                                v-else>Comments</span>
                        </button>
                        <!--end::Action-->
                    </div>

                    <!--begin::Comments-->
                    <div v-for="(item, i) in visibleFeeds" :key="i" :id="'feedreplies-' + item.id" v-if="!dashboard">
                        <div class="d-flex pt-5">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-40 symbol-light-success mr-5 mt-1">
                                <span class="symbol-label bg-white">
                                    <img :src="item.user.profileimage" class="h-100 align-self-end br-42" alt="" />
                                </span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Info-->
                            <div class="d-flex flex-column flex-row-fluid">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center flex-wrap">
                                    <a href="#"
                                        class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{
            item.user.firstname }}</a>
                                    <span class="text-dark font-weight-normal flex-grow-1 font-size-sm">
                                        <timeago :datetime="formatDate(item.datetime)"></timeago>
                                    </span>
                                    <button class="text-dark font-weight-normal font-size-sm"
                                        @click="setReply(item.id)">Reply</button>
                                    <button class="text-dark font-weight-normal font-size-sm ml-2"
                                        v-if="currentUser.user_id == item.user.user_id"
                                        @click="deletePost(item.id, i)">Delete</button>
                                </div>
                                <span class="text-dark font-size-sm font-weight-normal pt-1">{{ item.text }}</span>
                                <!--end::Info-->
                            </div>
                            <!--end::Info-->
                        </div>
                        <div v-if="item.replyCount > 0" class="pt-2 pl-10">
                            <span class="text-dark font-weight-normal flex-grow-1 font-size-sm pointer"
                                @click="getReplies(item.id, i)">
                                --Show {{ item.replyCount }} <span v-if="item.replyCount == 1">reply</span><span
                                    v-else>replies</span>
                            </span>
                        </div>
                        <div v-if="item.replies.length > 0">
                            <div class="d-flex pl-15" v-for="(item, i) in item.replies" :key="i"
                                :id="'replies-' + item.id">
                                <div class="w100">
                                    <div class="d-flex pt-5">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-40 symbol-light-success mr-5 mt-1">
                                            <span class="symbol-label bg-white">
                                                <img :src="item.user.profileimage" class="h-100 align-self-end br-42"
                                                    alt="" />
                                            </span>
                                        </div>
                                        <!--end::Symbol-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-column flex-row-fluid">
                                            <!--begin::Info-->
                                            <div class="d-flex align-items-center flex-wrap">
                                                <a href="#"
                                                    class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{
            item.user.firstname }}</a>
                                                <span class="text-dark font-weight-normal flex-grow-1 font-size-sm">
                                                    <timeago :datetime="formatDate(item.datetime)"></timeago>
                                                </span>
                                                <!-- <span class="text-dark font-weight-normal font-size-sm">Reply</span> -->
                                                <button class="text-dark font-weight-normal font-size-sm ml-2"
                                                    v-if="currentUser.user_id == item.user.user_id"
                                                    @click="deleteReply(item.commentid, item.id)">Delete</button>
                                            </div>
                                            <span class="text-dark font-size-sm font-weight-normal pt-1">{{ item.text
                                                }}</span>
                                            <!--end::Info-->
                                        </div>
                                        <!--end::Info-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Comments-->

                    <!-- begin::Load More-->
                    <button
                        class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5"
                        @click="feedVisible += step" v-if="feedVisible < feedList.length && !dashboard">More</button>
                    <!-- end::Load More-->


                    <!--end::Bottom-->
                    <!--begin::Separator-->
                    <div class="separator separator-solid mt-5 mb-4" v-if="!dashboard"></div>
                    <!--end::Separator-->
                    <!--begin::Add Comment-->
                    <form class="position-relative" v-if="!dashboard && currentTextfield == 'comment'">
                        <textarea v-model="form.post" id="kt_forms_widget_9_input"
                            class="form-control border-0 p-0 pr-10 resize-none" rows="1"
                            placeholder="Add a comment"></textarea>
                        <div class="position-absolute top-0 right-0 mt-n1 mr-n2" @click="addPost">
                            <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                <i class="flaticon2-send-1 icon-ms"></i>
                            </span>
                        </div>
                    </form>
                    <form class="position-relative" v-if="!dashboard && currentTextfield == 'reply'">
                        <textarea v-model="replyForm.reply" id="kt_forms_widget_9_input"
                            class="form-control border-0 p-0 pr-10 resize-none" rows="1"
                            placeholder="Add a reply"></textarea>
                        <div class="position-absolute top-0 right-0 mt-n1 mr-n2" @click="addReply">
                            <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                <i class="flaticon2-send-1 icon-ms"></i>
                            </span>
                        </div>
                        <div class="position-absolute top-0 r30 mt-n1 mr-n2" @click="closeReply">
                            <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                <i class="flaticon2-cancel icon-ms"></i>
                            </span>
                        </div>
                    </form>
                    <!--edit::Add Comment-->
                </div>
                <!--end::Session-->
                <!--begin::Photos-->
                <div class="card card-custom" v-if="item.type == 'photo' && item.online == true">
                    <!--begin::Body-->
                    <div class="card-body">
                        <!--begin::Top-->
                        <div class="d-flex align-items-center">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-45 symbol-light mr-5">
                                <span class="symbol-label bg-white">
                                    <span
                                        class="symbol symbol-45 symbol-xxl-45 mr-5 align-self-start align-self-xxl-center ml-15">
                                        <img :src="item.user.profileimage" alt="image" />
                                    </span>
                                </span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Info-->
                            <div class="d-flex flex-column flex-grow-1">
                                <div class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder">
                                    {{ item.user.firstname }}
                                </div>
                                <div class="d-flex">
                                    <div class="d-flex align-items-center pr-5">
                                        <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Clock.svg-->
                                            <span class="padr-1">
                                                <i class="fa fa-clock icon-nm text-primary"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <span class="text-dark font-weight-bold">
                                            <timeago :datetime="formatDate(item.datetime)"></timeago>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--begin::Dropdown-->
                            <router-link :to="{ name: 'feeddetailother', params: { feedId: item.id } }"
                                v-show="showMore">
                                <button
                                    class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5">
                                    More <i class="fa fa-angle-down icon-nm text-white"></i>
                                </button>
                            </router-link>
                            <!--end::Dropdown-->

                            <!--end::Info-->
                        </div>
                        <!--end::Top-->
                        <!--begin::Bottom-->
                        <div class="pt-3">
                            <!--begin::Text-->
                            <p class="text-dark font-size-lg font-weight-normal pt-5" v-if="item.title"
                                v-html="item.title">
                            </p>
                            <p class="pt-5 mb-6" v-else>&nbsp;</p>
                            <!--end::Text-->
                            <!--begin::Image-->
                            <div class="bgi-no-repeat bgi-size-cover rounded min-h-295px">
                                <!-- <Vue-Gallery :images="item.photoUrls" :index="index" @close="index = null"></Vue-Gallery> -->
                                <div class="image" v-for="(image, imageIndex) in item.photoUrls" :key="imageIndex">
                                    <b-img rounded fluid :src="image"></b-img>
                                </div>
                            </div>
                            <!--end::Image-->
                        </div>


                        <!--end::Bottom-->
                    </div>
                    <div class="card-body gutter-b">
                        <span
                            class="btn btn-danger btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5 nocursor"
                            v-if="item.likeCount > 0">

                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                            <span class="svg-icon svg-icon-md svg-icon-primary mr-0">
                                <i class="fa fa-heart icon-nm text-white"></i>
                            </span>

                            <!--end::Svg Icon-->
                            {{ item.likeCount }} <span v-if="item.likeCount == 1">Like</span><span v-else>Likes</span>
                        </span>
                        <!--begin::Action-->

                        <!--begin::Action-->
                        <router-link
                            :to="{ name: 'feeddetail', params: { feedId: item.id, sessionId: item.sessionid, coachlistId: item.coachlistid } }"
                            v-if="item.messageCount > 0 && dashboard">
                            <button
                                class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5">

                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                                <!-- <span class="btn btn-icon btn-sm text-white">
                                                        <i class="flaticon2-sms icon-ms text-white"></i>
                                                    </span> -->

                                <!--end::Svg Icon-->
                                {{ item.messageCount }} <span v-if="item.messageCount == 1">Comment</span><span
                                    v-else>Comments</span>
                            </button>
                        </router-link>
                        <button
                            class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5 mr-5"
                            v-if="item.messageCount > 0 && !dashboard" @click="firstLoad">

                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                            <!-- <span class="btn btn-icon btn-sm text-white">
                                                        <i class="flaticon2-sms icon-ms text-white"></i>
                                                    </span> -->

                            <!--end::Svg Icon-->
                            {{ item.messageCount }} <span v-if="item.messageCount == 1">Comment</span><span
                                v-else>Comments</span>
                        </button>
                        <!--end::Action-->
                        <!--begin::Comments-->
                        <div v-for="(item, i) in visibleFeeds" :key="i" :id="'feedreplies-' + item.id"
                            v-if="!dashboard">
                            <div class="d-flex pt-5">
                                <!--begin::Symbol-->
                                <div class="symbol symbol-40 symbol-light-success mr-5 mt-1">
                                    <span class="symbol-label bg-white">
                                        <img :src="item.user.profileimage" class="h-100 align-self-end br-42" alt="" />
                                    </span>
                                </div>
                                <!--end::Symbol-->
                                <!--begin::Info-->
                                <div class="d-flex flex-column flex-row-fluid">
                                    <!--begin::Info-->
                                    <div class="d-flex align-items-center flex-wrap">
                                        <a href="#"
                                            class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{
            item.user.firstname }}</a>
                                        <span class="text-dark font-weight-normal flex-grow-1 font-size-sm">
                                            <timeago :datetime="formatDate(item.datetime)"></timeago>
                                        </span>
                                        <button class="text-dark font-weight-normal font-size-sm"
                                            @click="setReply(item.id)">Reply</button>
                                        <button class="text-dark font-weight-normal font-size-sm ml-2"
                                            v-if="currentUser.user_id == item.user.user_id"
                                            @click="deletePost(item.id, i)">Delete</button>
                                    </div>
                                    <span class="text-dark font-size-sm font-weight-normal pt-1">{{ item.text }}</span>
                                    <!--end::Info-->
                                </div>
                                <!--end::Info-->
                            </div>
                            <div v-if="item.replyCount > 0" class="pt-2 pl-10">
                                <span class="text-dark font-weight-normal flex-grow-1 font-size-sm pointer"
                                    @click="getReplies(item.id, i)">
                                    --Show {{ item.replyCount }} <span v-if="item.replyCount == 1">reply</span><span
                                        v-else>replies</span>
                                </span>
                            </div>
                            <div v-if="item.replies.length > 0">
                                <div class="d-flex pl-15" v-for="(item, i) in item.replies" :key="i"
                                    :id="'replies-' + item.id">
                                    <div class="w100">
                                        <div class="d-flex pt-5">
                                            <!--begin::Symbol-->
                                            <div class="symbol symbol-40 symbol-light-success mr-5 mt-1">
                                                <span class="symbol-label bg-white">
                                                    <img :src="item.user.profileimage"
                                                        class="h-100 align-self-end br-42" alt="" />
                                                </span>
                                            </div>
                                            <!--end::Symbol-->
                                            <!--begin::Info-->
                                            <div class="d-flex flex-column flex-row-fluid">
                                                <!--begin::Info-->
                                                <div class="d-flex align-items-center flex-wrap">
                                                    <a href="#"
                                                        class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{
            item.user.firstname }}</a>
                                                    <span class="text-dark font-weight-normal flex-grow-1 font-size-sm">
                                                        <timeago :datetime="formatDate(item.datetime)"></timeago>
                                                    </span>
                                                    <!-- <span class="text-dark font-weight-normal font-size-sm">Reply</span> -->
                                                    <button
                                                        class="text-dark font-weight-normal font-size-sm ml-2">Delete</button>
                                                </div>
                                                <span class="text-dark font-size-sm font-weight-normal pt-1">{{
            item.text
        }}</span>
                                                <!--end::Info-->
                                            </div>
                                            <!--end::Info-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end::Comments-->

                        <!-- begin::Load More-->
                        <button
                            class="btn btn-ingomublue btn-sm text-white rounded font-weight-bolder font-size-sm p-2 mt-5"
                            @click="feedVisible += step"
                            v-if="feedVisible < feedList.length && !dashboard">More</button>
                        <!-- end::Load More-->


                        <!--end::Bottom-->
                        <!--begin::Separator-->
                        <div class="separator separator-solid mt-5 mb-4" v-if="!dashboard"></div>
                        <!--end::Separator-->
                        <!--begin::Add Comment-->
                        <form class="position-relative" v-if="!dashboard && currentTextfield == 'comment'">
                            <textarea v-model="form.post" id="kt_forms_widget_9_input"
                                class="form-control border-0 p-0 pr-10 resize-none" rows="1"
                                placeholder="Add a comment"></textarea>
                            <div class="position-absolute top-0 right-0 mt-n1 mr-n2" @click="addPost">
                                <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                    <i class="flaticon2-send-1 icon-ms"></i>
                                </span>
                            </div>
                        </form>
                        <form class="position-relative" v-if="!dashboard && currentTextfield == 'reply'">
                            <textarea v-model="replyForm.reply" id="kt_forms_widget_9_input"
                                class="form-control border-0 p-0 pr-10 resize-none" rows="1"
                                placeholder="Add a reply"></textarea>
                            <div class="position-absolute top-0 right-0 mt-n1 mr-n2" @click="addReply">
                                <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                    <i class="flaticon2-send-1 icon-ms"></i>
                                </span>
                            </div>
                            <div class="position-absolute top-0 r30 mt-n1 mr-n2" @click="closeReply">
                                <span class="btn btn-icon btn-sm btn-hover-icon-primary">
                                    <i class="flaticon2-cancel icon-ms"></i>
                                </span>
                            </div>
                        </form>
                        <!--begin::Top-->
                        <div class="d-flex align-items-center pt-10">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-45 symbol-light mr-5">
                                <span class="symbol-label bg-white">
                                    <span
                                        class="symbol symbol-45 symbol-xxl-45 mr-5 align-self-start align-self-xxl-center ml-15">
                                        <img :src="item.coach.profileimage" alt="image" />
                                    </span>
                                </span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Info-->
                            <div class="d-flex flex-column flex-grow-1">
                                <div class="d-flex">
                                    <div
                                        class="d-flex align-items-center pr-5 text-dark font-size-xl font-weight-bolder">
                                        Coach
                                    </div>
                                </div>
                                <div class="text-dark text-hover-primary mb-1 font-size-lg">
                                    {{ item.coach.first }} {{ item.coach.last }}
                                </div>
                            </div>
                            <!--end::Info-->
                        </div>
                        <!--end::Top-->

                    </div>


                    <!--end::Action-->

                    <!--end::Body-->
                </div>
                <!--end::Photos-->
            </div>
            <!--end::Feed Widget-->
        </div>
    </div>
</template>

<script>
import formateDateMixin from "@/mixins/formatDate"
// import VueGallery from 'vue-gallery';
import VideoPlayer from "@/view/pages/dashboard/FeedVideo.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import * as fb from '../../../api/firebase.js';

export default {
    name: 'feed',
    components: {
        // VueGallery,
        VideoPlayer,
    },
    props: {
        coachFeed: {
            default: () => [],
            type: Array
        },
        showMore: {
            default: false,
            type: Boolean
        },
        dashboard: {
            default: false,
            type: Boolean
        }
    },
    mixins: [formateDateMixin],
    data() {
        return {
            currentUser: [],
            index: null,
            showDropDown: false,
            feedList: [],
            replyList: [],
            feedVisible: 10,
            step: 10,
            feedIndex: 0,
            replyCommentId: "",
            currentTextfield: "comment",
            form: {
                post: "",
            },
            replyForm: {
                reply: "",
            },
        }
    },
    async mounted() {
        let user = await fb.firebase.getCurrentUser()
        fb.usersCollection.where("user_id", "==", user.uid).onSnapshot((snapshot) => {
            if (snapshot.docs.length > 0) {
                snapshot.docs.map((doc) => {
                    let user = doc.data()
                    this.currentUser = user
                })
            }
        }, (error) => { })
        if (!this.dashboard) {
            this.firstLoad()
        }
    },
    methods: {
        firstLoad() {
            this.feedList = []
            this.$store.commit("RESET_ALL_DASHBOARDFEED_MESSAGES")
            this.$store.dispatch("feedGetAllComments", {
                feedid: this.$props.coachFeed[0].id,
                coachlistid: this.$props.coachFeed[0].coachlistid
            })
        },
        setReply(commentid) {
            this.currentTextfield = "reply"
            this.replyCommentId = commentid
        },
        closeReply() {
            this.currentTextfield = "comment"
            this.replyCommentId = ""
        },
        getReplies(commentid, index) {
            this.feedIndex = index
            this.$store.dispatch("feedGetReplies", {
                commentid: commentid
            })
        },
        addReply() {
            if (this.replyForm.reply === "" && this.replyCommentId === "") {
                Swal.fire({
                    title: "",
                    text: "You must enter a reply!",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            } else {
                const newpost = this.replyForm.reply;
                this.$store.dispatch("addNewReplyToFeed", {
                    feedid: this.$props.coachFeed[0].id,
                    feedcommentid: this.replyCommentId,
                    text: newpost,
                });
                this.replyForm.reply = "";
                this.closeReply()
                this.firstLoad()
                Swal.fire({
                    title: "",
                    text: "Your reply is being added",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            }
        },
        addPost() {
            if (this.form.post === "") {
                Swal.fire({
                    title: "",
                    text: "You must enter a message!",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            } else {
                const newpost = this.form.post;
                this.$store.dispatch("addCommentToFeed", {
                    feedid: this.$props.coachFeed[0].id,
                    post: newpost
                });
                this.form.post = "";
                Swal.fire({
                    title: "",
                    text: "Your comment has been added!",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            }
        },
        deletePost(commentid, index) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert post!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.feedList.splice(index, 1);
                    this.$store.dispatch("deleteFeedComment", {
                        feedid: this.$props.coachFeed[0].id,
                        commentId: commentid,
                    });
                    Swal.fire("Deleted!", "Your post has been deleted.", "success");
                }
            });
        },
        deleteReply(feedcommentid, feedreplyid) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert your reply!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("deleteFeedReply", {
                        feedid: this.$props.coachFeed[0].id,
                        commentId: feedcommentid,
                        replyId: feedreplyid,
                    });
                    Swal.fire("Deleted!", "Your reply is being deleted.", "success");
                }
            });
        },

    },
    computed: {
        ...mapGetters(["feedAllComments", "feedAllReplies"]),
        visibleFeeds() {
            return this.feedAllComments.slice(0, this.feedVisible)
        }
    },
    watch: {
        feedAllComments: {
            handler(newValue) {
                this.feedList = newValue
            },
            deep: true
        },
        // feedAllComments(newValue) {
        //     console.log("feed comments changed")
        //     this.feedList = newValue
        //     console.log(newValue)
        // },
        feedAllReplies(newValue) {
            this.feedList[this.feedIndex].replies = newValue
        }
    }
}
</script>

<style scoped>
.r30 {
    right: 30px !important
}

.nocursor {
    cursor: none !important;
}

.w100 {
    width: 100% !important
}

.pointer {
    cursor: pointer;
}

.fs-15 {
    font-size: 1.5rem !important;
}

.p-025 {
    padding: 0 25px;
}

.hw60100 {
    height: 60px !important;
    width: auto !important;
}

.hw60 {
    height: 60px;
    width: 60px;
}

.sessionimage {
    object-fit: contain;
    margin: 0 auto;
}

.symbol.symbol-light .symbol-label {
    background-color: white;
}

.symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.ml-15 {
    margin-left: 15px !important
}

.padr-1 {
    padding-right: 1px !important;
}

.image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 5px;
}

.video-js,
.vjs-tech {
    position: absolute !important;
    height: 100% !important;
    border-radius: none !important;
}

.smartphone {
    position: relative;
    width: 360px;
    height: 600px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
    width: 360px;
    height: 640px;
    background: white;
}

.br-42 {
    border-radius: 0.42rem;
}
</style>
