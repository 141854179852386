<template>
    <div class="wh100">
        <video ref="videoPlayer" class="video-js"></video>
    </div>
</template>

<script>
import videojs from 'video.js';
import "video.js/dist/video-js.min.css";
import "@videojs/themes/dist/city/index.css";
const aws = require("aws-sdk");
import {
    registerIVSQualityPlugin,
    registerIVSTech,
} from 'amazon-ivs-player';

export default {
    name: 'VideoPlayer',
    props: {
        url: {
            default: "",
            type: String
        },
        poster: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            player: null
        }
    },
    mounted() {
        registerIVSTech(videojs, {
            wasmWorker: this.createAbsolutePath(
                "/assets/amazon-ivs-wasmworker.min.js"
            ),
            wasmBinary: this.createAbsolutePath(
                "/assets/amazon-ivs-wasmworker.min.wasm"
            ),
        });
        registerIVSQualityPlugin(videojs)
        if (this.url != "") {
            var s3 = new aws.S3();
            var params = { Bucket: "ingomu-streaming", Key: this.url };
            var s3url = s3.getSignedUrl("getObject", params);
            let videoOptions = {
                autoplay: false,
                controls: true,
                techOrder: ["AmazonIVS"],
                height: "600",
                aspectRatio: "16:9",
                responsive: true,
                poster: this.poster
            }
            this.player = videojs(this.$refs.videoPlayer, videoOptions, () => {
                this.player.log('onPlayerReady', this);
            });
            this.player.enableIVSQualityPlugin();
            const events = this.player.getIVSEvents();
            const ivsPlayer = this.player.getIVSPlayer();
            ivsPlayer.addEventListener(events.PlayerState.PLAYING, () => {  });
            this.player.src({
                src: s3url,
                type: "application/x-mpegURL"
            });
        }
    },
    methods: {
        createAbsolutePath(assetPath) {
            return new URL(assetPath, document.URL).toString();
        },
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
}
</script>

<style scoped>
.wh100 {
    width: 100%;
    height: 100%;
}

.video-js.vjs-16-9,
.video-js.vjs-4-3,
.video-js.vjs-fluid {
    height: 100% !important
}
</style>
