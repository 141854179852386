<template>
    <div style="background-color: #fff; height: 100vh;">
        <!-- START MAIN CONTAINER -->
        <div class="container-fluid p-0">
            <div class="row">

                <!-- START SIDENAV -->
                <div class="side-nav col-lg-3 col-md-12 pr-0">

                    <!-- START NEW CHAT BUTTON -->
                    <div class="row p-2 row-pad">
                        <ul class="list-unstyled w-100">

                            <!-- START SETTINGS -->
                            <li class="nav-item">
                                <p class="h6 nav-link fs-12">
                                    <img class="ingomulogo width41" :src="defaultHostProfileImage" />
                                    Sample Questions
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div class="row p15">
                        <div class="col-12 row-pad">
                            <ul class="list-styled mt0">
                                <!-- START SETTINGS -->
                                <li :id="'card-' + i" class="nav-item" v-for="(item, i) in shownQuestions" :key="i">
                                    <div class="card bg-dark" @click="addSuggestion(item, i)">
                                        <div class="card-body pad1rem text-left">
                                            {{ item }}
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 row-pad">
                            <ul class="list-styled">

                                <!-- START SETTINGS -->
                                <li class="nav-item" @click="resetChat">
                                    <a href="javascript:void(0)" class="nav-link fs-12">
                                        <i class="fas fa-comments"></i> New chat
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <!-- START CONTENT -->
                <div class="content p-0 pt-2 col-lg-9 col-md-12">
                    <div class="chat-area" id="scrollChatContainer" ref="scrollChatContainer">

                        <!-- Default Chat Opening -->
                        <div class="row gpt-chat-box" v-if="showFirstResponse">
                            <div class="chat-icon">
                                <img class="chatgpt-icon" :src="defaultHostProfileImage" />
                            </div>
                            <div class="chat-txt whitespacenormal">
                                {{firstResponse}}
                            </div>
                        </div>


                        <div class="chat-content-area" v-for="(chat, i) in chatHistory" :key="i">

                            <!-- START USER CHAT -->
                            <div class="row user-chat-box" v-if="chat.from == 'user'">
                                <div class="chat-icon">
                                    <img class="user-icon" :src="userProfileImage" />
                                </div>
                                <div class="chat-txt" v-html="chat.response"></div>
                            </div>

                            <!-- START GPT CHAT -->
                            <div class="row gpt-chat-box" v-if="chat.from == 'system'">
                                <div class="chat-icon">
                                    <img class="chatgpt-icon" :src="defaultHostProfileImage" />
                                </div>
                                <div v-if="typeStatus == false" :id="'chat-txt-' + i" class="chat-txt one"
                                    v-html="chat.response"></div>
                                <div v-else-if="i < chatHistory.length - 1" :id="'chat-txt-' + i" class="chat-txt two"
                                    v-html="chat.response"></div>
                                <div v-else :id="'chat-txt-' + i" class="chat-txt three" v-html="lastResponse"></div>
                            </div>

                        </div>
                        <!-- Loading new chat -->
                        <div v-if="showWaiting">

                            <!-- START USER CHAT -->
                            <!-- <div class="row user-chat-box">
                                <div class="chat-icon">
                                    <img class="user-icon" :src="userProfileImage" />
                                </div>
                                <div class="chat-txt" v-html="showWaitMessage"></div>
                            </div> -->

                            <!-- START GPT CHAT -->
                            <div class="row gpt-chat-box">
                                <div class="chat-icon">
                                    <img class="chatgpt-icon" :src="defaultHostProfileImage" />
                                </div>
                                <div class="chat-txt">
                                    <div class="loading">
                                        <span class="loading__dot"></span>
                                        <span class="loading__dot"></span>
                                        <span class="loading__dot"></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- START CHAT INPUTS -->
                    <div class="chat-input-area overflow-hidden verybottom">
                        <div class="row">
                            <div class="col-12 chat-inputs-area-inner">
                                <form class="position-relative">
                                    <!-- <label class="text-white">Characters Remaining:
                                        {{
                            messageForm.maxChars - messageForm.message.length
                        }}</label> -->
                                    <div class="row chat-inputs-container d-flex align-items-center">
                                        <textarea name="message" id="message" :maxlength="messageForm.maxChars"
                                            class="col-11 fs-12" v-model="messageForm.message"
                                            placeholder="Ready to transform your life? How can we help?"></textarea>
                                        <button type="button" @click="addMessage()">
                                            <span class="col-1">
                                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                            </span>
                                        </button>
                                    </div>
                                    <label class="pt10 fs-12">Characters Remaining:
                                        {{
                                        messageForm.maxChars - messageForm.message.length
                                        }}</label>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="text-center w-100 fs-14 pt-5">
                    Eric at Project Pinskey has the potential for errors, so it's important to verify any critical details.
            </div>
            </div>
        </div>
        <!-- START FOOTER -->
        <!-- <footer class="d-flex align-items-center justify-content-center position-relative bottom-0">
            <div class="text-center fs-14">
                Eric has the potential to err, so it's wise to verify critical details.
            </div>
        </footer> -->
    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
    name: "coachrecommend",
    data() {
        return {
            defaultUserProfileImage: "https://ingomucdn.s3.amazonaws.com/chat/user-icon.png",
            defaultHostProfileImage: "",
            randomQuestions: [
                "I need help setting goals.",
                "How can I reduce stress?",
                "I need parenting help.",
                "What coach can help me with dating?",
                "How do I unlock my full potential?",
                "My finances are a mess.",
                "I want to be a better leader.",
                "I need help with co-parenting and setting boundaries with my ex.",
            ],
            shownQuestions: [
            "I need help setting goals.",
                "How can I reduce stress?",
                "I need parenting help.",
                "What coach can help me with dating?",
                "How do I unlock my full potential?",
                "My finances are a mess.",
                "I want to be a better leader.",
                "I need help with co-parenting and setting boundaries with my ex.",
            ],
            firstResponse: "",
            firstResponseValue: "",
            showFirstResponse: false,
            currentDate: new Date,
            chatHistory: [],
            showWaiting: false,
            showWaitMessage: "",
            typeStatus: false,
            charIndex: 0,
            lastResponse: "",
            lastResponseValue: "",
            messageForm: {
                message: "",
                maxChars: 500
            },
        }
    },
    mounted() {
        this.$store.dispatch("getChatData")
        this.$store.dispatch("getCurrentCoachDetail");
    },
    computed: {
        ...mapGetters(["aiChatHistory", "userProgramCoachDetail", "chatData"]),
        userProfileImage() {
            return this.defaultUserProfileImage
        }
    },
    methods: {
        addMessage() {
            if (this.messageForm.message === "") {
                Swal.fire({
                    title: "",
                    text: "You must enter a message!",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            } else {
                this.showWaitMessage = this.messageForm.message
                this.showWaiting = true
                this.updateScroll()
                this.chatHistory.push({
                    from: "user",
                    response: this.messageForm.message
                })
                this.$store.dispatch("askAIQuestion", {
                    message: this.messageForm.message
                })
                this.messageForm.message = "";
            }
        },
        addSuggestion(message, position) {
            this.showWaitMessage = message
            this.showWaiting = true
            this.shownQuestions = this.shownQuestions.filter(item => item !== message)
            this.updateScroll()
            this.chatHistory.push({
                from: "user",
                response: message
            })
            this.$store.dispatch("askAIQuestion", {
                message: message
            })
        },
        updateScroll() {
            this.$nextTick(
                () =>
                (this.$refs.scrollChatContainer.scrollTop =
                    this.$refs.scrollChatContainer.scrollHeight)
            );
        },
        typeText() {
            if (this.charIndex < this.lastResponseValue.length) {
                if (!this.typeStatus) this.typeStatus = true;
                this.lastResponse += this.lastResponseValue.charAt(
                    this.charIndex
                );
                this.charIndex += 1;
                this.updateScroll()
                setTimeout(this.typeText, Math.floor(Math.random() * (1 - 20 + 1) + 1));
            } else {
                this.typeStatus = false;
            }
        },
        startFirstText() {
            this.showFirstResponse = true
            this.firstResponse = ""
            this.charIndex = 0
            this.firstText()
        },
        firstText() {
            if (this.charIndex < this.firstResponseValue.length) {
                this.firstResponse += this.firstResponseValue.charAt(
                    this.charIndex
                );
                this.charIndex += 1;
                this.updateScroll()
                setTimeout(this.firstText, Math.floor(Math.random() * (1 - 20 + 1) + 1));
            }
        },
        resetChat() {
            // this.chatHistory = []
            this.firstResponse = ""
            this.lastResponse = ""
            this.lastResponseValue = ""
            setTimeout(this.startFirstText, 1500)
            this.chatHistory = []
            this.shownQuestions = this.randomQuestions
            this.$store.dispatch("deleteChatData")
        }
    },
    watch: {
        aiChatHistory(newValue) {
            if (newValue.length > 0) {
                const rndInt = Math.floor(Math.random() * (3 - 2 + 1) + 2);
                const rndCoaches = newValue.slice(0, rndInt);
                let resultdescription = "";
                rndCoaches[rndCoaches.length - 1].forEach(element => {
                    const addedHtml = 
                    "<img src='" + 
                    element.coach[0].image + 
                    "' style='max-width:100px; border-radius: 25%;'>" +
                    "\n\n" +
                    "<h4 style='font-weight:bold;'>" + element.coach[0].name + "</h4>" +
                    element.description +
                    "\n\n" +
                    "<h4 style='font-weight:bold;'>Coach Bio:</h4>" +
                    element.bio +
                    "\n\n" +
                    "<h4 style='font-weight:bold;'>Upcoming live sessions:</h4>" +
                    element.calendar +
                    "\n\n\n";
                    resultdescription = resultdescription.concat(addedHtml)
                });
                this.showWaiting = false
                this.showWaitMessage = ""
                this.typeStatus = true
                this.charIndex = 0
                this.lastResponse = ""
                let lastAiResponse = resultdescription.trim()
                this.lastResponseValue = lastAiResponse
                this.chatHistory.push({
                    from: "system",
                    response: resultdescription.trim()
                })
                this.typeText()
                this.updateScroll()
            } else {
                this.showWaiting = false
            }
            // console.log(newValue[0][0].description)
            // if (this.chatHistory.length == 0) {
            //     console.log("chat history ", this.chatHistory)
            //     newValue.forEach(element => {
            //         this.showWaiting = false
            //         this.showWaitMessage = ""
            //         this.typeStatus = true
            //         this.charIndex = 0
            //         this.lastResponse = ""
            //         let lastAiResponse = element.response
            //         // this.lastResponseValue = lastAiResponse
            //         this.chatHistory.push(element)
            //         this.typeText()
            //         this.updateScroll()
            //     })
            // } else {
            //     if (this.chatHistory.length < newValue.length) {
            //         this.showWaiting = false
            //         this.showWaitMessage = ""
            //         this.typeStatus = true
            //         this.charIndex = 0
            //         this.lastResponse = ""
            //         let lastAiResponse = newValue[newValue.length - 1].response
            //         // this.lastResponseValue = lastAiResponse
            //         this.chatHistory.push(newValue[newValue.length - 1])
            //         this.typeText()
            //         this.updateScroll()
            //     }
            // }
        },
        userProgramCoachDetail(newValue) {
            if (newValue.length > 0) {
                this.defaultUserProfileImage = newValue[0].user.profileimage
            }
        },
        chatData(newValue) {
            if (newValue.length > 0) {
                this.firstResponseValue = newValue[0].firstResponse.trim()
                this.defaultHostProfileImage = newValue[0].profileImage
                if (this.chatHistory.length == 0) {
                    setTimeout(this.startFirstText, 1500)
                    this.chatHistory = []
                }
            }
            // this.$store.dispatch("getAIChatHistory");
        }
    }
}
</script>
<style scoped>
* {
    box-sizing: border-box;
}

body {
    background-color: #eee;
    margin: 0;
    padding: 0;
    color: white;
    padding: 1px;
    padding-top: 2px;
}

.side-nav {
    background-color: #FFF;
    margin: 0px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    height: 95vh;
}

.chat-btn {
    border: 1px solid #fff;
    height: 40px;
    border-radius: 5px;
}

.chat-btn:hover {
    background-color: #fff;
    transition: background-color 0.5s ease;
}

.gpt-model {
    background-color: #1f2123;
}

.gpt-temperature {
    background-color: #1f2123;
}

input[type="range"] {
    width: 80%;
}

input[type="range"]::-webkit-slider-runnable-track {
    height: 3px;
    margin: 10px 0;
    width: 100%;
    background: #c5c5d2;
}

input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #ffffff;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

.side-nav ul {
    list-style: none;
    padding: 0;
    margin: 0px;
}

.side-nav li {
    padding: 10px;
}

.side-nav .list-styled li:hover {
    background-color: #fff;
    border-radius: 10px;
}

.side-nav i {
    margin-right: 10px;
}

.content {
    background-color: #FFF;
    margin: 0px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}

.chat-area {
    height: calc(100vh - 170px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.chat-content-area {
    overflow-x: hidden;
    overflow-y: hidden;
}

.chat-inputs-container {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}

.chat-inputs-container textarea {
    background-color: transparent;
    outline: none;
    border: none;
    color: #3F4254;
    resize: none;
}

.chat-inputs-area-inner {
    padding-top: 30px;
    padding-left: 150px;
    padding-right: 150px;
}

.fa-paper-plane {
    color: grey;
    cursor: pointer;
}

.chatgpt-icon {
    width: 50px;
    border-radius: 0.42rem;
}

.user-icon {
    width: 40px;
    border-radius: 0.42rem;
}

.user-chat-box {
    padding: 20px;
    padding-left: 100px;
    color: #3F4254;
}

.form-select {
    border: 1px solid rgb(96, 95, 95);
    height: 40px;
    border-radius: 5px;
}

small {
    font-size: 11px;
    padding: 12px;
    display: block;
}

.gpt-chat-box {
    background-color: #FFF;
    padding: 20px;
    padding-left: 100px;
    color: #3F4254;
}

.chat-icon {
    width: 10%;
    margin: 0;
    padding: 0;
}

.chat-txt {
    width: 60%;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.7rem;
    white-space: pre-wrap;
}

footer {
    background-color: #fff;
    color: #000;
    height: 30px;
    font-size: 11px;
}

::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #565869;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 768px) {
    .user-chat-box {
        padding-left: 20px;
    }

    .gpt-chat-box {
        padding-left: 20px;
    }

    .chat-inputs-area-inner {
        padding-left: 50px;
        padding-right: 54px;
        margin-bottom: 30px;
    }
}

.width41 {
    width: 41px;
}

.verybottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
}

.row-pad {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.bold {
    font-weight: bold;
}

.pr-0 {
    padding-right: 0 !important
}

.height100vh {
    height: 100vh;
}

.pt10 {
    padding-top: 10px;
}

.pad1rem {
    padding: 1rem !important;
}

.blinking-cursor {
    margin-left: 5px;
    background-color: #fff;
    animation: blink 1s infinite;
}

@keyframes blink {

    0%,
    50% {
        opacity: 1;
    }

    50.1%,
    100% {
        opacity: 0;
    }
}

.loading {
    align-items: start;
    display: flex;
    justify-content: start;
    height: 100%;
    width: 100%;
}

.loading__dot {
    animation: dot ease-in-out 1s infinite;
    background-color: grey;
    display: inline-block;
    height: 20px;
    margin: 10px;
    width: 20px;
    border-radius: 15px;
}

.loading__dot:nth-of-type(2) {
    animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
    animation-delay: 0.3s;
}

@keyframes dot {
    0% {
        background-color: grey;
        transform: scale(1);
    }

    50% {
        background-color: #3B5998;
        transform: scale(1.3);
    }

    100% {
        background-color: grey;
        transform: scale(1);
    }
}

.fs-12 {
    font-size: 1.2rem;
}

.card {
    background-color: #ececec !important;
    cursor: pointer;
}

.p15 {
    padding: 0 15px;
}

.mt0 {
    margin-top: 0 !important;
}

.fs-14 {
    font-size: 14px;
}

.avatar {
    border-radius: 50%;
    max-width: 100px;
}

.whitespacenormal {
    white-space: normal;
}
</style>