<template>
    <div>
        <div class="col-lg-12 mt-10">
            <h3 class="font-weight-bold mb-3 text-dark">So how can I help?</h3>
            <div class="font-weight-nromal font-size-lg mb-6">
                <p>
                    Upload an image below and see what Maura thinks of it. Once uploaded, Maura will score your image automatically. If the result
                    shows green, your image will pass,
                    if it shows red, it fails. Red images will be marked as an image that does not meet our
                    community guidelines. Your privacy
                    and security mean everything to us. Anything you enter upload below is completely anonymous. Project Pinskey will
                    collect that data and periodically
                    retrain itself to become smarter. A win-win for everyone.
                </p>
            </div>
        </div>
        <div class="col-lg-12 mt-6">
            <b-overlay :show="showNsfw" rounded="sm">
                <div style="margin: 0 auto;">
                    <div class="mb-3">
                        <div class="form-group mt-10">
                            <b-form @submit.stop.prevent="onSubmit">
                                <div class="d-flex mb-3">
                                    <b-form-file accept="image/*" v-model="image" placeholder="Choose an image"
                                        class="w-auto flex-grow-1"></b-form-file>
                                    <b-button v-if="hasImage" variant="danger" class="ml-3" @click="clearImage">Clear
                                        image</b-button>
                                </div>
                                <div v-if="hasImage" class="d-flex">
                                    <div class="d-flex mr-7">
                                        <span class="svg-icon svg-icon-gray-500 mt-1">
                                            <i v-if="nsfwScore > .50" class="flaticon2-cross icon-lg text-red"></i>
                                            <i v-else class="flaticon2-checkmark icon-lg text-green"></i>
                                        </span>
                                        <span v-if="nsfwScore > .50"
                                            class="font-weight-boldest text-primary ml-2 text-red fs-25">Blocked</span>
                                        <span v-else
                                            class="font-weight-boldest text-primary ml-2 text-green fs-25">Safe</span>
                                    </div>
                                </div>
                                <b-img v-if="hasImage" :src="imageSrc" class="mb-3 mh-300" fluid block rounded></b-img>
                                <!-- <b-button :disabled="!hasImage" variant="primary" type="submit">Upload image</b-button> -->
                            </b-form>
                            <span class="form-text text-dark">Version 3.24.1</span>
                        </div>
                    </div>
                </div>
                <div v-if="hasImage" class="d-flex align-items-sm-center flex-wrap flex-column flex-sm-row">
                </div>
            </b-overlay>
        </div>
        <div v-if="hasImage" class="col-xl-12 hr-grey mt-10">
            <!--begin::Form-->
            <form class="form" novalidate="novalidate" id="kt_save_changes_form">
                <div class="mt-10">
                    <h3 class="font-weight-bold mb-3 text-dark">So, how did Maura do?</h3>
                    <div class="font-weight-nromal font-size-lg mb-6">
                        <p>
                            If Maura got the classification right, click the thumbs up. If Maura got it wrong, click the red icon. Maura will use your feedback to continue learning. 
                        </p>
                    </div>
                    <div class="row">
                        <!-- <div class="col-xl-2"></div> -->
                        <div class="col-xl-12">
                            <!--begin::Input-->
                            <div class="form-group">
                                <div>
                                    <b-button class="mr-5" size="lg" variant="success" v-on:click="thankyou">
                                        <span class="svg-icon svg-icon-gray-500 mr-0">
                                            <i class="flaticon-like icon-lg pr-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="lg" variant="danger" v-on:click="thankyou">
                                        <span class="svg-icon svg-icon-gray-500 mr-0">
                                            <i class="flaticon2-delete icon-lg pr-0"></i>
                                        </span>
                                    </b-button>
                                </div>
                            </div>
                            <!--end::Input-->
                        </div>
                        <!-- <div class="col-xl-2"></div> -->
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

const base64Encode = data =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export default {
    name: 'imageclassification',
    data() {
        return {
            image: null,
            imageSrc: null
        }
    },
    computed: {
        ...mapGetters(["nsfwScore", "showNsfw"]),
        hasImage() {
            return !!this.image;
        }
    },
    watch: {
        image(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then(value => {
                            this.imageSrc = value;
                            this.$store.dispatch("calculateNsfwScore", {
                                imgSrc: this.imageSrc
                            })
                        })
                        .catch(() => {
                            this.imageSrc = null;
                        });
                } else {
                    this.imageSrc = null;
                }
            }
        }
    },
    methods: {
        clearImage() {
            this.image = null;
        },
        thankyou() {
            Swal.fire({
                title: "Thank You",
                text: "Your feedback is appreciated.",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        }
    },
}
</script>

<style scoped>
.mh-300 {
    max-height: 300px;
}

.text-green {
    color: green !important;
}

.text-red {
    color: red !important;
}

.fs-25 {
    font-size: 25px;
}

.hr-grey {
    border-top: 1px solid #EBEDF3;
}
</style>