<template>
    <div>
        <!-- begin::Entry -->
        <div class="d-flex flex-column flex-row-fluid">
            <!--begin::Container-->
            <div class="container-fluid py-8">
                <!--begin::Education-->
                <div class="d-flex flex-row">
                    <UserNav activePage="dashboard" activeTab="1"></UserNav>
                    <!--begin::Content-->
                    <div class="flex-row-fluid ml-lg-8">
                        <div>
                            <b-card no-body>
                                <b-tabs pills card>
                                    <b-tab title="Feed" active><b-card-text>
                                            <div class="row">
                                                <div class="col-xxl-6"
                                                    v-if="coachFeed.length > 0 || allFeed.length > 0">
                                                    <FeedBox v-if="currentUser.isCoach" :coachFeed=coachFeed
                                                        v-bind:showMore=true v-bind:dashboard="true" />
                                                    <FeedBox v-else :coachFeed=allFeed v-bind:showMore=true
                                                        v-bind:dashboard="true" />
                                                    <div class="d-flex flex-center pb-1" v-if="moreAllFeedPending">
                                                        <button
                                                            class="btn btn-primary font-weight-bolder font-size-sm py-3 px-9"
                                                            id="kt_app_education_more_feeds_btn" @click="loadMore">More
                                                            Feed</button>
                                                    </div>
                                                </div>
                                                <div class="col-xxl-6" v-else>
                                                    <div class="card card-custom gutter-b h300">
                                                        <!--begin::Body-->
                                                        <div class="card-body pt-0">
                                                            <!--begin::Scroll-->
                                                            <div>
                                                                <!--begin::Messages-->
                                                                <div class="messages">
                                                                    <!--begin::Message In-->
                                                                    <h5>
                                                                        <div class="text-center center">
                                                                            <p>
                                                                                There are no feeds yet!
                                                                            </p>
                                                                            <p class="pt-5">
                                                                                <i
                                                                                    class="fa fa-video-slash icon-2x"></i>
                                                                            </p>
                                                                        </div>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xxl-6 order-first order-xl-2">
                                                    <!-- begin::Card -->
                                                    <div class="card card-custom gutter-b" v-if="currentUser.isCoach">
                                                        <!--begin::Header-->
                                                        <div class="card-header border-0">
                                                            <h3 class="card-title font-weight-bolder text-dark">Messages
                                                            </h3>
                                                        </div>
                                                        <!--end::Header-->
                                                        <!--begin::Body-->
                                                        <div class="card-body pt-0">
                                                            <b-container fluid>
                                                                <!-- User Interface controls -->
                                                                <b-row>
                                                                    <b-col lg="12" class="my-1">
                                                                        <b-form-group label="Filter"
                                                                            label-for="filter-input" label-cols-sm="2"
                                                                            label-align-sm="right" label-size="sm"
                                                                            class="mb-0">
                                                                            <b-input-group size="sm">
                                                                                <b-form-input id="filter-input"
                                                                                    v-model="filter" type="search"
                                                                                    placeholder="Type to Search"></b-form-input>

                                                                                <b-input-group-append>
                                                                                    <b-button :disabled="!filter"
                                                                                        @click="filter = ''">Clear</b-button>
                                                                                </b-input-group-append>
                                                                            </b-input-group>
                                                                        </b-form-group>
                                                                    </b-col>
                                                                </b-row>

                                                                <b-table id="messages-table" :items="coachMessageItems"
                                                                    :fields="messagefields" responsive="sm"
                                                                    :head-variant="headVariant" :per-page="perPage"
                                                                    :current-page="currentMessagesPage"
                                                                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                                                    :sort-compare="mySortCompare" show-empty
                                                                    thead-class="hidden_header" :filter="filter"
                                                                    empty-text="You have no new messages.">
                                                                    <template #cell(image)="data">
                                                                        <div class="symbol symbol-50 mw-50 pointer"
                                                                            v-on:click="loadMessage(data.item)">
                                                                            <img alt="Pic"
                                                                                :src="data.item.user.userProfileImage" />
                                                                        </div>
                                                                    </template>
                                                                    <template #cell(title)="data">
                                                                        <div class="d-flex flex-column"
                                                                            v-on:click="loadMessage(data.item)">
                                                                            <a href="javascript:void()"
                                                                                class="text-dark text-hover-primary font-weight-bold font-size-lg">{{
                                                        data.item.user.userName }}</a>
                                                                            <span
                                                                                class="text-dark font-weight-bold font-size-sm pointer">{{
                                                        data.item.text.substring(0,
                                                            100) }}
                                                                                <span
                                                                                    class="text-dark font-weight-bold font-size-sm"
                                                                                    v-if="data.item.text.length > 100">...</span>
                                                                            </span>
                                                                        </div>
                                                                    </template>
                                                                    <template #cell(datetime)="data">
                                                                        <div class="d-flex flex-column mnw-150 text-right"
                                                                            v-if="data.item.coachRead">
                                                                            <span
                                                                                class="text-dark font-weight-bold font-size-sm">
                                                                                <timeago
                                                                                    :datetime="formatDate(data.item.datetime)">
                                                                                </timeago>
                                                                            </span>
                                                                        </div>
                                                                        <div class="d-flex flex-column mnw-150 text-right"
                                                                            v-else>
                                                                            <span
                                                                                class="text-dark font-weight-bold font-size-sm">
                                                                                <button type="button"
                                                                                    class="btn btn-ingomublue btn-sm text-white">New</button>
                                                                            </span>
                                                                        </div>
                                                                    </template>
                                                                </b-table>
                                                                <b-pagination v-model="currentMessagesPage"
                                                                    :total-rows="messageRows" :per-page="perPage"
                                                                    aria-controls="sessions-table"></b-pagination>
                                                            </b-container>
                                                        </div>
                                                    </div>
                                                    <!--end: Card-->

                                                    <b-modal id="bv-modal-messages" size="lg" :title="messagesTitle"
                                                        scrollable centered ref="messages">
                                                        <!--begin::Messages-->
                                                        <b-container fluid>
                                                            <div v-if="loading">
                                                                <div
                                                                    class="d-flex justify-content-center mb-3 pt-100 pb-100">
                                                                    <b-spinner style="width: 3rem; height: 3rem;"
                                                                        label="Loading..."></b-spinner>
                                                                </div>
                                                            </div>
                                                            <div id="messages" class="messages" v-else>
                                                                <!--begin::Message In-->
                                                                <div v-for="(item, i) in coachUserMessageItems"
                                                                    :key="i">
                                                                    <!--begin::Message Out-->
                                                                    <div class="d-flex flex-column mb-5 align-items-end"
                                                                        v-if="item.fromCoach">
                                                                        <div class="d-flex align-items-center">
                                                                            <div>
                                                                                <span class="text-dark font-size-sm"
                                                                                    v-if="item.datetime != ''">
                                                                                    <timeago
                                                                                        :datetime="formatDate(item.datetime)">
                                                                                    </timeago>
                                                                                </span>
                                                                                <span class="text-dark font-size-sm"
                                                                                    v-else>
                                                                                    Just now
                                                                                </span>
                                                                                <a href="#"
                                                                                    class="text-dark text-hover-primary font-weight-bold font-size-h6 pl-5">You</a>
                                                                            </div>
                                                                            <div
                                                                                class="symbol symbol-45 symbol-xxl-45 mr-5 align-self-start align-self-xxl-center ml-3">
                                                                                <img alt="Pic"
                                                                                    :src="item.coach.coachProfileImage" />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="mt-2 rounded p-5 bg-ingomublue text-white font-weight-bold font-size-lg text-right max-w-400px html-text">
                                                                            {{ item.text }}
                                                                        </div>
                                                                    </div>
                                                                    <!--end::Message Out-->

                                                                    <!--begin::Message Out-->
                                                                    <div class="d-flex flex-column mb-5 align-items-start"
                                                                        v-else>
                                                                        <div class="d-flex align-items-center">
                                                                            <div
                                                                                class="symbol symbol-45 symbol-xxl-45 mr-5 align-self-start align-self-xxl-center mr-3">
                                                                                <img alt="Pic"
                                                                                    :src="item.user.userProfileImage" />
                                                                            </div>
                                                                            <div>
                                                                                <a href="#"
                                                                                    class="text-dark text-hover-primary font-weight-bold font-size-h6 pr-10">{{
                                                        item.user.userName }}</a>
                                                                                <span class="text-dark font-size-sm"
                                                                                    v-if="item.datetime != ''">
                                                                                    <timeago
                                                                                        :datetime="formatDate(item.datetime)">
                                                                                    </timeago>
                                                                                </span>
                                                                                <span class="text-dark font-size-sm"
                                                                                    v-else>
                                                                                    just now
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="mt-2 rounded p-5 bg-ingomugreen text-white font-weight-bold font-size-lg text-left max-w-400px html-text">
                                                                            {{ item.text }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!--end::Message In-->
                                                            </div>
                                                        </b-container>
                                                        <!--end::Messages-->
                                                        <!--begin::Footer-->
                                                        <template #modal-footer>
                                                            <form class="position-relative w-100">
                                                                <div class="align-items-center w-100">
                                                                    <!--begin::Compose-->
                                                                    <textarea v-model="form.post"
                                                                        class="form-control border-0 p-0" rows="2"
                                                                        placeholder="Type a message"></textarea>
                                                                    <div
                                                                        class="align-items-center justify-content-between mt-5 text-right">
                                                                        <div v-if="replyLoading == true">
                                                                            <b-spinner
                                                                                style="width: 2rem; height: 2rem;"
                                                                                label="Loading..."></b-spinner>
                                                                        </div>
                                                                        <div v-else>
                                                                            <button type="button" @click="addPost"
                                                                                class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">Send</button>
                                                                        </div>
                                                                    </div>
                                                                    <!--begin::Compose-->
                                                                </div>
                                                            </form>
                                                        </template>
                                                        <!--end::Footer-->
                                                    </b-modal>

                                                    <!-- begin::Card -->
                                                    <div class="card card-custom gutter-b">
                                                        <!--begin::Header-->
                                                        <div class="card-header border-0">
                                                            <h3 class="card-title font-weight-bolder text-dark">Sessions
                                                            </h3>
                                                        </div>
                                                        <!--end::Header-->
                                                        <!--begin::Body-->
                                                        <div class="card-body pt-0">
                                                            <b-table id="sessions-table"
                                                                :items="dashboardProgramSessions" :fields="fields"
                                                                responsive="sm" :head-variant="headVariant"
                                                                :per-page="perPage" :current-page="currentPage"
                                                                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                                                :sort-compare="mySortCompare" show-empty>

                                                                <template #cell(datetime)="data">
                                                                    <router-link
                                                                        :to="{ name: 'feeddetail', params: { feedId: data.item.id, sessionId: data.item.sessionid, coachlistId: data.item.coachlistid } }">
                                                                        {{ shortFormatDate(data.item.datetime) }}
                                                                    </router-link>
                                                                </template>
                                                                <template #cell(title)="data">
                                                                    <router-link
                                                                        :to="{ name: 'feeddetail', params: { feedId: data.item.id, sessionId: data.item.sessionid, coachlistId: data.item.coachlistid } }"
                                                                        class="text-dark">
                                                                        <span class="fssmaller">{{ data.item.coach.first
                                                                            }} {{
                                                        data.item.coach.last }}</span>
                                                                        <br>
                                                                        {{ data.item.title }}
                                                                    </router-link>
                                                                </template>
                                                                <template #cell(status)="data">
                                                                    <div class="text-center">
                                                                        <span
                                                                            class="label label-lg label-light-success label-inline font-weight-bold py-4 mr-5"
                                                                            v-if="data.item.online">Online</span>
                                                                        <span
                                                                            class="label label-lg label-light-danger label-inline font-weight-bold py-4 mr-5"
                                                                            v-else>Offline</span>
                                                                    </div>
                                                                </template>
                                                                <template #cell(messageCount)="data">
                                                                    <div class="text-center">
                                                                        {{ data.item.messageCount }}
                                                                    </div>
                                                                </template>
                                                            </b-table>
                                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                                :per-page="perPage"
                                                                aria-controls="sessions-table"></b-pagination>
                                                        </div>
                                                        <!--end: Card Body-->
                                                    </div>
                                                    <!--end: Card-->
                                                </div>
                                            </div>

                                        </b-card-text></b-tab>
                                    <b-tab>
                                        <template #title>
       Coach Recommendation&nbsp;&nbsp;<b-badge variant="danger">New</b-badge>
      </template>
      <div class="col-lg-12">
                                            <h3 class="font-weight-bold mb-3 text-dark">What is Project Pinskey?</h3>
                                            <div class="font-weight-nromal font-size-lg mb-6">
                                                <p>
                                                    Project Pinskey is our advanced machine learning and artificial intelligence program that enables our application to autonomously learn from coach and user data, without the need for explicit programming.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mt-10">
                                            <h3 class="font-weight-bold mb-3 text-dark"> Project Pinskey?
                                            </h3>
                                            <div class="font-weight-nromal font-size-lg mb-6">
                                                <p>
                                                    We use Project Pinskey to monitor livestreams, message board conversations, and content to identify potentially harmful or inappropriate material. It also analyzes user questions to recommend coaches who can address specific issues,
                                                     providing a more robust and engaging experience for our users. Project Pinskey helps maintain the safety of the platform for both our coaches and users.
                                                </p>
                                                <p>
                                                    Maura is the part of Project Pinskey that reviews text and images. Eric is responsible for recommending coaches based on user input.
                                                </p>
                                            </div>
                                        </div>

                                        <div>
                                            <b-card no-body>
                                                <b-tabs pills card>
                                                    <b-tab title="Text"><b-card-text>
                                                            <Mltext />
                                                        </b-card-text></b-tab>
                                                    <b-tab title="Images">
                                                        <Mlimage />
                                                    </b-tab>
                                                    <b-tab title="Recommendation" active>
                                                        <Mlrecommend />
                                                    </b-tab>
                                                </b-tabs>
                                            </b-card>
                                        </div>

                                    </b-tab>
                                </b-tabs>
                            </b-card>
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Education-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import loadMoreMixin from "@/mixins/loadMore"
import UserNav from "@/view/layout/header/UserNav.vue";
import FeedBox from "@/view/pages/feed/Feed.vue";
import formateDateMixin from "@/mixins/formatDate"
import * as fb from '../../../api/firebase.js';
import Swal from "sweetalert2";
import Mltext from "@/view/pages/machinelearning/textclassification"
import Mlimage from "@/view/pages/machinelearning/imageclassification"
import Mlrecommend from "@/view/pages/machinelearning/coachrecommend";

export default {
    name: "coachfeed",
    async mounted() {
        this.$store.dispatch("getCoachListInfo")
        let user = await fb.firebase.getCurrentUser()
        fb.usersCollection.where("user_id", "==", user.uid).onSnapshot((snapshot) => {
            if (snapshot.docs.length > 0) {
                snapshot.docs.map((doc) => {
                    let user = doc.data()
                    this.currentUser = user
                    if (user.isCoach == true) {
                        this.$store.dispatch("getFeedPagination", {
                            limit: 20
                        })
                        this.$store.dispatch("getAllDashboardProgramSessions")
                    } else {
                        this.$store.dispatch("getAllFeedPagination", {
                            limit: 20
                        })
                        this.$store.dispatch("getDashboardProgramSessionsNoId")
                    }
                })
            }
        }, (error) => { })
    },
    components: {
        UserNav,
        FeedBox,
        Mltext,
        Mlimage,
       Mlrecommend,
    },
    mixins: [formateDateMixin, loadMoreMixin],
    data() {
        return {
            loading: false,
            replyLoading: false,
            refresh: false,
            perPage: 5,
            currentPage: 1,
            currentMessagesPage: 1,
            sortBy: 'datetime',
            sortDesc: true,
            headVariant: "light",
            sessionid: "",
            toMessageTitle: "",
            coachlistid: "",
            coachProfileImage: "",
            coachName: "",
            coachUserId: "",
            fields: [
                { key: "datetime", label: "Date", sortable: true },
                { key: "title", label: "Name/Title" },
                { key: "status", label: "Status" },
                { key: "messageCount", label: "Messages", sortable: true },
            ],
            messagefields: [
                { key: "image", label: "Image" },
                { key: "title", label: "Name/Title", tdClass: 'w-100' },
                { key: "datetime", label: "Date", sortable: true, tdClass: 'va-m' },
            ],
            filter: null,
            currentUser: [],
            coachMessageItems: [],
            coachUserMessageItems: [],
            form: {
                post: "",
            },
        }
    },
    computed: {
        ...mapGetters({
            coachList: "programCoachDetail",
            dashboardProgramSessions: "dashboardProgramSessions",
            coachFeed: "coachFeed",
            moreAllFeedPending: "moreAllFeedPending",
            allFeed: "allFeed",
            moreAllAllFeedPending: "moreAllAllFeedPending",
            chatHistory: "chatHistory",
            videoIssues: "videoIssues",
            coachMessages: "coachMessages",
            userProgramCoachDetail: "userProgramCoachDetail",
            coachMessagesForUser: "coachMessagesForUser",
            coachListInfo: "coachListInfo"
        }),
        rows() {
            return this.dashboardProgramSessions.length
        },
        messageRows() {
            return this.coachMessageItems.length
        },
        messagesTitle() {
            return this.toMessageTitle
        }
    },
    methods: {
        mySortCompare(a, b, key) {
            if (key === 'datetime') {
                // Assuming the date field is a `Date` object, subtraction
                // works on the date serial number (epoch value)
                return a[key] - b[key]
            } else {
                // Let b-table handle sorting other fields (other than `date` field)
                return false
            }
        },
        loadMore() {
            if (this.currentUser.isCoach == true) {
                if (
                    this.moreAllFeedPending &&
                    this.coachFeed.length > 0
                ) {
                    this.$store.dispatch("getFeedPagination", {
                        limit: 5
                    });
                }
            } else {
                if (
                    this.moreAllAllFeedPending &&
                    this.allFeed.length > 0
                ) {
                    this.$store.dispatch("getAllFeedPagination", {
                        limit: 5
                    });
                }
            }
        },
        reloadSessionsList() {
            this.refresh = true
            if (this.currentUser.isCoach == true) {
                setTimeout(() => (this.$store.dispatch("resetAllFeed")), 2500)
            } else {
                setTimeout(() => (this.$store.dispatch("resetAllAllFeed")), 2500)
            }
            // this.$store.dispatch("resetAllSessions")
        },
        loadMessage(dataItem) {
            this.toMessageTitle = dataItem.user.userName
            this.$store.dispatch("resetCoachMessagesForUser")
            this.$store.dispatch("getCoachMessageForUser", { coachid: this.coachlistid, userid: dataItem.userid })
            this.loading = true
            this.$bvModal.show("bv-modal-messages");
            setTimeout(() => {
                var elmt = document.getElementById("messages")
                elmt.scrollIntoView(false)
            }, 1000)
        },
        addPost() {
            if (this.form.post === "") {
                Swal.fire({
                    title: "",
                    text: "You must enter a message!",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            } else {
                if (this.coachMessagesForUser.length > 0) {
                    const newpost = this.form.post;
                    let oldMessages = this.coachUserMessageItems
                    var newCoachMessage = [{
                        fromCoach: true,
                        datetime: "",
                        text: newpost,
                        coach: {
                            coachProfileImage: this.coachProfileImage
                        }
                    }]
                    this.coachMessageItems = newCoachMessage.push(oldMessages)
                    var elmt = document.getElementById("messages")
                    elmt.scrollIntoView(false)
                    this.replyLoading = true
                    this.$store.dispatch("addCoachMessage", {
                        coachlistid: this.coachlistid,
                        coachname: this.coachName,
                        coachprofileimage: this.coachProfileImage,
                        coachuserid: this.coachUserId,
                        text: newpost,
                        username: this.coachMessagesForUser[0].user.userName,
                        userprofileimage: this.coachMessagesForUser[0].user.userProfileImage,
                        userid: this.coachMessagesForUser[0].user.userid
                    });
                    this.form.post = "";
                    // Swal.fire({
                    //     title: "",
                    //     text: "Your comment has been added!",
                    //     icon: "success",
                    //     confirmButtonClass: "btn btn-secondary",
                    //     heightAuto: true,
                    // });
                }
            }
        },
    },
    watch: {
        coachFeed(newValue) {
            this.refresh = false
        },
        coachListInfo(newValue) {
            if (this.coachlistid == "") {
                this.coachProfileImage = newValue[0].coach.image
                this.coachUserId = newValue[0].coach.user_id
                this.coachName = newValue[0].coach.first + " " + newValue[0].coach.last
                let coachid = newValue[0].id
                this.coachlistid = coachid
                this.$store.dispatch("getCoachMessagesList", { coachid: coachid })
            }
        },
        coachMessages(newValue) {
            this.coachMessageItems = newValue
            var elmt = document.getElementById("messages")
            elmt.scrollIntoView(false)
            this.replyLoading = false
        },
        coachMessagesForUser(newValue) {
            this.coachUserMessageItems = newValue
            this.loading = false
        },
    },
    beforeUnmount() {
        this.$store.dispatch("resetCoachMessagesForUser")
    }
}
</script>

<style scoped>
.h300 {
    height: 300px;
    overflow: scroll;
}

.center {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.fs-15 {
    font-size: 1.5rem !important;
}

.p-025 {
    padding: 0 25px;
}

.hw60100 {
    height: 60px !important;
    width: auto !important;
}

.hw60 {
    height: 60px;
    width: 60px;
}

.sessionimage {
    object-fit: contain;
    margin: 0 auto;
}

.symbol.symbol-light .symbol-label {
    background-color: white;
}

.symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.fssmaller {
    font-size: 12px;
    font-weight: bold;
}

.font-size-lg {
    font-size: 1.08rem !important;
}

.text-dark {
    color: #3F4254 !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.mw-40 {
    min-width: 40px !important;
}

.mw-50 {
    min-width: 50px !important;
}

.mnw-150 {
    min-width: 150px !important;
}

.pointer {
    cursor: pointer;
}

.table-responsive-sm {
    min-height: 200px !important;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pr-10 {
    padding-right: 10px !important
}

.pl-5 {
    padding-left: 5px !important;
}

.html-text {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
}

.bg-ingomublue {
    background-color: #3b5998;
}

.bg-ingomugreen {
    background-color: #46c346;
}

/deep/ .table>tbody>tr>td.va-m {
    vertical-align: middle !important;
}
</style>
