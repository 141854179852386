export default {
    data() {
        return {
            bottom: false,
        };
    },
    methods: {
        loadMore() {
        },
        bottomVisible() {
            const scrollY = window.scrollY;
            const visible = document.documentElement.clientHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const bottomOfPage = visible + scrollY >= pageHeight;
            return bottomOfPage || pageHeight < visible;
        },
        onScroll() {
            var exampleHeading = this.$refs["example"];
            if (exampleHeading) {
                var marginTopExample = exampleHeading.getBoundingClientRect().top;
                var innerHeight = window.innerHeight;
                if (marginTopExample - innerHeight < 0) {
                    this.loadMore();
                }
            }
        },
        resetList() {
        },
    },
    watch: {
        bottom(bottom) {
            if (bottom) {
                this.loadMore();
            }
        },
    },
    created() {
        window.addEventListener("scroll", () => {
            this.bottom = this.bottomVisible();
        });
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
}